import React, { useState } from "react";
import Link from "gatsby-link";

import ProjectCard from "../ProjectCard/ProjectCard";

// Keep this in sync with static/admin/config.yml
const categories = [
  "Solar",
  "Utility & Power Line",
  "Industrial & Mining",
  "Commercial & Residential"
];

function ProjectListing({ postEdges }) {
  // const [selectedCategory, setSelectedCategory] = useState("Utility Scale");

  function getPostList() {
    const postList = [];
    postEdges.forEach((postEdge) => {
      postList.push({
        path: postEdge.node.fields.slug.replace(/^.*\//, ""),
        tags: postEdge.node.frontmatter.tags,
        // cover: postEdge.node.frontmatter.cover,
        thumbnail: postEdge.node.frontmatter.thumbnail,
        location: postEdge.node.frontmatter.location,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.frontmatter.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
        category: postEdge.node.frontmatter.category
      });
    });
    return postList;
  }

  // function onOptionChangeHandler(event) {
  //   setSelectedCategory(event.target.value);
  // }

  // render() {
  const postList = getPostList();
  return (
    <>
      {/* <select name="Project Categories" id="project-categories" onChange={onOptionChangeHandler}>
        <optgroup label="Renewable Energy">
          <option value="Utility Scale">Utility Scale</option>
          <option value="Carports">Carports</option>
          <option value="Commercial">Commercial</option>
          <option value="Solar">Solar</option>
        </optgroup>
        <optgroup label="Industrial">
          <option value="Mining">Mining</option>
          <option value="Industrial Facilities">Industrial Facilities</option>
        </optgroup>
        <optgroup label="Transmission & Distribution">
          <option value="Substations">Substations</option>
          <option value="Power Lines">Power Lines</option>
        </optgroup>
        <optgroup label="Protection & Control (P&C)">
          <option value="Arc Flash Analysis">Arc Flash Analysis</option>
          <option value="Protective Device Coordination Studies">
            Protective Device Coordination Studies
          </option>
          <option value="Relay Settings & Programming">Relay Settings & Programming</option>
        </optgroup>
        <optgroup label="Electrical Vehicle">
          <option value="EV Charging Stations">EV Charging Stations</option>
        </optgroup>
        <optgroup label="Commercial">
          <option value="Office">Office</option>
          <option value="Mixed-Use & Retail">Mixed-Use & Retail</option>
        </optgroup>
      </select> */}
      {/* selectedCategory */}
      <>
        {/* <h1 className="title" style={{ color: "#434a57" }}>
          {selectedCategory}
        </h1> */}
        <div className="columns is-multiline">
          {postList
            // .filter((post) => post.category === selectedCategory)
            .map((post) => (
              <div className="column is-half">
                <ProjectCard project={post} />
              </div>
            ))}
        </div>
      </>
      {/* {categories.map((category) => (
        <>
          <h1 className="title" style={{ color: "#434a57" }}>
            {category}
          </h1>
          <div className="columns is-multiline">
            {postList
              .filter((post) => post.category === category)
              .map((post) => (
                <div className="column is-half">
                  <ProjectCard project={post} />
                </div>
              ))}
          </div>
        </>
      ))} */}
    </>
  );
}
// }

// {/* <div
//   key={post.title}
//   className="card"
//   style={{
//     marginTop: index === 0 ? 0 : 24,
//     backgroundColor: "white" /*this.props.backgroundColor*/,
//   }}
// >
//   <div className="card-content">
//     <div className="media">
//       <div className="media-content">
//         <p
//           className={"title is-4 " + post.title}
//           style={{ color: "black" /*this.props.textColor*/ }}
//         >
//           {post.title}
//         </p>
//       </div>
//     </div>
//     <div className={"content " + this.props.fontColor}>
//       {post.excerpt}
//       <br />
//       <Link to={post.path}>Read more</Link>
//     </div>
//   </div>
// </div> */}

export default ProjectListing;
