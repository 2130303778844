import React, { Component } from "react";
import Link from "gatsby-link";

class ProjectCard extends Component {
  render() {
    return (
      <div style={styles.cardStyle}>
        <div className="card-image image">
          <img
            // src={require(`../../../assets/images/projects/site-overview-3.jpg`)}
            // src={require(`../../..${this.props.project.thumbnail}`)}
            src={this.props.project.thumbnail}
            alt="Project image"
            style={{
              height: 370 // Can tweak this if needed.
            }}
          />
        </div>
        <div>
          <div className="media">
            <div className="media-content">
              <p
                className="title is-4 has-text-centered has-text-weight-bold padding-top-10"
                // style={{ minHeight: 64 }}
              >
                {this.props.project.title}
              </p>
              <p className="subtitle is-5 has-text-centered">{this.props.project.location}</p>
            </div>
          </div>
          <div className="content" style={styles.description}>
            {this.props.project.excerpt}
          </div>
        </div>
        <Link to={`/projects/${this.props.project.path}`}>
          {this.props.homepage ? "Read more" : <div style={styles.readMoreStyle}>Read more</div>}
        </Link>
      </div>
    );
  }
}

const styles = {
  cardStyle: {
    margin: 10
    // width: "100%",
  },
  description: {
    paddingTop: 10,
    textAlign: "justify"
  },
  readMoreStyle: {
    width: "100%",
    backgroundColor: "#3273dc",
    display: "flex",
    justifyContent: "center",
    padding: 10,
    marginTop: 5,
    color: "white"
  }
};

export default ProjectCard;
