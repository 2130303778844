import React, { Component } from "react";

class FullWidthImage2 extends Component {
  render() {
    return (
      <div
        className="full-width-image-container-2 margin-top-0"
        style={{ backgroundImage: `url(${this.props.image})` }}
      >
        <span style={{ borderBottom: "3px solid #118cff", marginTop: -20 }}>
          <h2
            className="is-uppercase has-text-weight-bold"
            style={{
              // paddingTop: '.5rem',
              opacity: "0.9",
              marginBottom: -6,
              fontSize: "5rem"
            }}
          >
            {this.props.title}
          </h2>
        </span>
      </div>
    );
  }
}

export default FullWidthImage2;
