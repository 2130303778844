import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
// import PostListing from "../components/PostListing/PostListing";
import ProjectListing from "../components/ProjectListing/ProjectListing";
import config from "../../data/SiteConfig";

import SEO from "../components/SEO/SEO";
import Content from "../components/Content/Content";
import FullWidthImage2 from "../components/FullWidthImage2/FullWidthImage2";

const img =
  "https://res.cloudinary.com/downtowndev/image/upload/q_auto,f_auto/american-public-power-association-419672-unsplash_c7cpaa.jpg";

export default class CategoryTemplate extends React.Component {
  render() {
    console.log("here **********************************************************");
    const { category } = this.props.pageContext;
    const postEdges = this.props.data.allMarkdownRemark.edges;
    console.log(postEdges);
    return (
      <Layout location={this.props.location}>
        <div className="underNavbar">
          <FullWidthImage2 title={category} image={img} />
          <Content className="page-content">
            <div className="posts-container">
              <Helmet htmlAttributes={{ lang: "en" }} title={`${category} | ${config.siteTitle}`} />
              <SEO />
              <ProjectListing postEdges={postEdges} />
            </div>
            {/* {this.renderPaging()} TODO: Add paging and style this. */}
          </Content>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CategoryPage($category: [String!]) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { category: { in: $category } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt(pruneLength: 280)
          timeToRead
          frontmatter {
            title
            # tags
            thumbnail
            date
            location
            category
          }
        }
      }
    }
  }
`;
